<template>
  <div :class="[rootClasses, 'base-currency']">
    <template v-if="showEventCoin">
      <UiImage :src="coinIcon" class="base-currency__event-icon" />
      &nbsp;
    </template>
    <slot />
    <template v-if="!showEventCoin">
      <component :is="currencyIcon" v-if="currencyIcon" class="base-currency__icon" />
      <span v-else class="base-currency__icon">&nbsp;{{ currencyText }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Currency } from '~/constants/currency.constants';
import type { IBaseCurrencyProps } from './BaseCurrency.types';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

const { defaultCurrency, siteCurrency } = useProjectSettings();

const props = defineProps<IBaseCurrencyProps>();
const { isEventCoin, customCurrency, withoutFake, overrideByDefault, isInlined } = toRefs(props);

const eventStore = useEventStore();
const { coinIcon } = storeToRefs(eventStore);

const userStore = useUserStore();
const { getIsFake } = storeToRefs(userStore);

const rootClasses = computed(() => ({
  'base-currency--default': !currencyIcon.value,
  'base-currency--inlined': isInlined.value,
  'base-currency--fake': isFakeIcon.value,
}));

const showEventCoin = computed(() => isEventCoin.value && coinIcon.value);
const isFakeIcon = computed(() => !withoutFake.value && getIsFake.value);

const isGoldIcon = computed(() => {
  return !customCurrency.value && !isFakeIcon.value && !overrideByDefault.value && siteCurrency === 'GOLD';
});

const currencyIcon = computed(() => {
  if (isGoldIcon.value) return 'SvgoCurrencyGold';
  return null;
});

const currencyText = computed(() => {
  if (isFakeIcon.value) return Currency['DIAMOND'];

  if (customCurrency.value) return Currency[customCurrency.value as keyof typeof Currency];

  return Currency[defaultCurrency];
});
</script>

<style scoped lang="scss" src="./BaseCurrency.scss"></style>
